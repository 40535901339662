import { combineReducers } from 'redux'
import login from './login'
import products from './products'
import carts from './carts'
import orders from './orders'
import misc from './misc'
import customers from './customers'
import customerThreads from './customerThreads'
import toast from './toast'
import stats from './stats'
import accounting from './accounting'
import listsParams from './listsParams'
import families from './families'

export default combineReducers({
  login,
  products,
  carts,
  orders,
  misc,
  customers,
  customerThreads,
  toast,
  stats,
  accounting,
  listsParams,
  families,
})
