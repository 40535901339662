import {
  GET_CUSTOM_LIST,
  POST_CUSTOM_LIST_ITEM,
  DELETE_CUSTOM_LIST_ITEM,
  PUT_CUSTOM_LIST_ITEM,
} from '../constants/actionTypes'

const miscReducer = (state = [], action) => {
  switch (action.type) {
    case GET_CUSTOM_LIST:
      return { ...state, [action.payload.list.wsResource]: action.payload.list }
    case POST_CUSTOM_LIST_ITEM:
      state[action.payload.wsResource].list.unshift(action.payload.item)
      return {
        ...state,
      }
    case PUT_CUSTOM_LIST_ITEM:
      const newList = state[action.payload.wsResource].list.filter(
        (item) => String(item.id) !== String(action.payload.itemId)
      )
      newList.unshift(action.payload.item)
      return {
        ...state,
        [action.payload.wsResource]: {
          ...state[action.payload.wsResource],
          list: newList,
        },
      }
    case DELETE_CUSTOM_LIST_ITEM:
      return {
        ...state,
        [action.payload.wsResource]: {
          ...state[action.payload.wsResource],
          list: state[action.payload.wsResource].list.filter(
            (item) => String(item.id) !== String(action.payload.itemId)
          ),
        },
      }

    default:
      return state
  }
}

export default miscReducer
