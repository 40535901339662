import axios from 'axios'
import { API_URL } from '../config'

const url = API_URL
axios.defaults.withCredentials = true

//--------------------//
// requètes Login     //
//--------------------//
export const userLogin = async (user) => axios.post(`${url}/login`, user)
//
export const logout = async () =>
  axios.post(`${url}/logout`, null, { withCredentials: true })
//
export const getUser = async () =>
  axios.get(`${url}/user`, { withCredentials: true })
//
export const refreshToken = async () =>
  axios.get(`${url}/refresh`, { withCredentials: true })

//--------------------//
//requètes Product    //
//--------------------//
//
//Product
//
export const createProduct = async (newProduct) =>
  axios.post(`${url}/products/new`, newProduct, { withCredentials: true })
//
export const getProduct = async (id) =>
  axios.get(`${url}/products/${id}`, { withCredentials: true })
//
export const getProductFull = async (idProduct) =>
  axios.get(`${url}/products/${idProduct}/full`, { withCredentials: true })
//
export const saveProduct = async (idProduct, productData) =>
  axios.post(`${url}/products/${idProduct}/save`, productData, {
    withCredentials: true,
  })
//
export const saveCo2 = async (dataObject) =>
  axios.post(`${url}/products/co2/data`, dataObject, { withCredentials: true })
//
export const changeLbcStatus = async (exportData) =>
  axios.post(`${url}/products/export-lbc`, exportData, {
    withCredentials: true,
  })
//
export const getProductOrders = async (idProduct) =>
  axios.get(`${url}/products/${idProduct}/orders`, { withCredentials: true })
//
export const getCollectPlaceInfos = async (idProduct, idCollectPlace) =>
  axios.get(
    `${url}/products/${idProduct}/collect-place-infos/${idCollectPlace}`,
    {
      withCredentials: true,
    }
  )
//
export const getLabel = async (idProduct) =>
  axios.get(`${url}/products/${idProduct}/label`, { withCredentials: true })
//
export const deleteProduct = async (idProduct) =>
  axios.delete(`${url}/products/${idProduct}/delete`, { withCredentials: true })
//
//Images
//
export const postProductImage = async (id, image) =>
  axios.post(`${url}/products/${id}/postimage`, image, {
    withCredentials: true,
  })
//
export const delProductImage = async (idProduct, idImage) =>
  axios.delete(`${url}/products/${idProduct}/image/${idImage}`, {
    withCredentials: true,
  })
//
export const putProductImage = async (idProduct, idImage, image) =>
  axios.post(`${url}/products/${idProduct}/image/${idImage}`, image, {
    withCredentials: true,
  })
//
//Options
//
export const getProductOptions = async () =>
  axios.get(`${url}/products/options/all`, {
    withCredentials: true,
  })
//
export const postProductOption = async (optionObject) =>
  axios.post(`${url}/products/new-option`, optionObject, {
    withCredentials: true,
  })
//
export const generateCombinations = async (combinationsArray) =>
  axios.post(`${url}/products/combinations`, combinationsArray, {
    withCredentials: true,
  })
//
export const deleteCombination = async (idCombination, idStock) =>
  axios.delete(
    `${url}/products/combinations/delete/${idCombination}/${idStock}`,
    {
      withCredentials: true,
    }
  )
//
export const deleteAllCombinations = async (idProduct) =>
  axios.delete(`${url}/products/combinations/delete-all/${idProduct}`, {
    withCredentials: true,
  })
//

//--------------------//
//requètes Lists      //
//--------------------//
//
//Products
//
export const getProductsListFull = async () =>
  axios.get(`${url}/lists/products`, { withCredentials: true })
//
//Orders
//
export const getOrdersList = async () =>
  axios.get(`${url}/lists/orders`, { withCredentials: true })
//
export const getCustomersList = async () =>
  axios.get(`${url}/lists/customers`, { withCredentials: true })
//
export const getProsList = async () =>
  axios.get(`${url}/lists/pros`, { withCredentials: true })
//
export const getPrivatesList = async () =>
  axios.get(`${url}/lists/privates`, { withCredentials: true })
//
export const getFamiliesList = async () =>
  axios.get(`${url}/lists/families`, { withCredentials: true })
//
//----------------------//
//requètes Custom lists //
//----------------------//
export const getCustomList = async (wsResource) =>
  axios.get(`${url}/lists/custom/${wsResource}`, { withCredentials: true })
//
export const postCustomListItem = async (wsResource, item) =>
  axios.post(`${url}/lists/custom/${wsResource}`, item, {
    withCredentials: true,
  })
//
export const putCustomListItem = async (wsResource, itemId, item) =>
  axios.post(`${url}/lists/custom/${wsResource}/${itemId}`, item, {
    withCredentials: true,
  })
//
export const deleteCustomListItem = async (wsResource, itemId) =>
  axios.delete(`${url}/lists/custom/${wsResource}/${itemId}`, {
    withCredentials: true,
  })

//--------------------//
//requètes Cart       //
//--------------------//
//
export const createCart = async (firstProduct) =>
  axios.post(`${url}/carts/new`, firstProduct, { withCredentials: true })
//
export const saveCart = async (appCart) =>
  axios.post(`${url}/carts/save`, appCart, { withCredentials: true })
//
export const getCartInfos = async (infosIds) =>
  axios.post(`${url}/carts/infos`, infosIds, { withCredentials: true })

//--------------------//
//requètes Customers  //
//--------------------//
//
export const createCustomer = async (customer) =>
  axios.post(`${url}/customers/new`, customer, { withCredentials: true })
//

export const getCustomerAddresses = async (idCustomer) =>
  axios.get(`${url}/customers/addresses/${idCustomer}`, {
    withCredentials: true,
  })
//
export const getCustomer = async (idCustomer) =>
  axios.get(`${url}/customers/card/${idCustomer}`, {
    withCredentials: true,
  })
//
export const createAddress = async (address) =>
  axios.post(`${url}/customers/address/new`, address, { withCredentials: true })
//
export const saveCustomer = async (customerObject) =>
  axios.post(`${url}/customers/save`, customerObject, { withCredentials: true })
//
export const saveAddress = async (addressObject) =>
  axios.post(`${url}/customers/address/save`, addressObject, {
    withCredentials: true,
  })
//
export const changeCustomerType = async (typeObject) =>
  axios.post(`${url}/customers/type`, typeObject, {
    withCredentials: true,
  })
//
export const getSourceSales = async (idCustomer, year) =>
  axios.get(`${url}/customers/sales/${idCustomer}/${year}`, {
    withCredentials: true,
  })
//
//--------------------//
//requètes Orders     //
//--------------------//
//
export const getOrder = async (idOrder) =>
  axios.get(`${url}/orders/${idOrder}/full`, { withCredentials: true })
//
export const changeOrderState = async (stateObject) =>
  axios.post(`${url}/orders/change-state`, stateObject, {
    withCredentials: true,
  })
//
export const createOrder = async (orderObject) =>
  axios.post(`${url}/orders/new`, orderObject, {
    withCredentials: true,
  })
//
export const getOrderOptions = async () =>
  axios.get(`${url}/orders/order-options`, { withCredentials: true })
//
export const sendOrderConf = async (orderInfos) =>
  axios.post(`${url}/orders/send-conf`, orderInfos, {
    withCredentials: true,
  })
//
export const changeOrderCustomer = async (order) =>
  axios.post(`${url}/orders/change-customer`, order, {
    withCredentials: true,
  })
//
export const changeOrderPayment = async (idPayment, paymentObject) =>
  axios.post(`${url}/orders/change-payment/${idPayment}`, paymentObject, {
    withCredentials: true,
  })
//
export const createOrderPayment = async (paymentObject) =>
  axios.post(`${url}/orders/new-payment`, paymentObject, {
    withCredentials: true,
  })
//
export const deleteOrderPayment = async (idPayment) =>
  axios.delete(`${url}/orders/delete-payment/${idPayment}`, {
    withCredentials: true,
  })
//
export const cancelOrder = async (orderObject) =>
  axios.post(`${url}/orders/cancel-order`, orderObject, {
    withCredentials: true,
  })
//
export const createCartRule = async (cartRuleObject) =>
  axios.post(`${url}/orders/new-cart-rule`, cartRuleObject, {
    withCredentials: true,
  })
//
//--------------------------//
//requètes Customer Thread  //
//--------------------------//
//
export const createThread = async (threadAndMessage) =>
  axios.post(`${url}/customer-threads/thread`, threadAndMessage, {
    withCredentials: true,
  })
//
export const createMessage = async (message) =>
  axios.post(`${url}/customer-threads/message`, message, {
    withCredentials: true,
  })
//
export const getCtList = async () =>
  axios.get(`${url}/customer-threads/threads-list`, {
    withCredentials: true,
  })
//
export const changeThread = async (threadId, thread) =>
  axios.post(`${url}/customer-threads/thread-update/${threadId}`, thread, {
    withCredentials: true,
  })
//
export const markMessagesRead = async (messages) =>
  axios.post(`${url}/customer-threads/mark-read`, messages, {
    withCredentials: true,
  })
//
export const markMessageNotRead = async (message) =>
  axios.post(`${url}/customer-threads/mark-not-read`, message, {
    withCredentials: true,
  })
//
//--------------------//
//requètes Stats      //
//--------------------//
//
export const getSites = async () =>
  axios.get(`${url}/stats/sites`, {
    withCredentials: true,
  })
//
export const statsPerSite = async (idSite) =>
  axios.get(`${url}/stats/per-sites/${idSite}`, {
    withCredentials: true,
  })
//
export const statsPerMonth = async (idMonth) =>
  axios.get(`${url}/stats/per-months/${idMonth}`, {
    withCredentials: true,
  })
//
export const statsPerYear = async (idYear) =>
  axios.get(`${url}/stats/per-years/${idYear}`, {
    withCredentials: true,
  })
//
export const statsStock = async () =>
  axios.get(`${url}/stats/actual-stock/`, {
    withCredentials: true,
  })
//
export const statsSinceStart = async () =>
  axios.get(`${url}/stats/since-start/`, {
    withCredentials: true,
  })
//
//--------------------//
//requètes Compta     //
//--------------------//
//
export const getMonthInvoices = async (month) =>
  axios.get(`${url}/accounting/month-invoices/${month}`, {
    withCredentials: true,
  })
//
export const getInvoice = async (idInvoice) =>
  axios.get(`${url}/accounting/invoice/${idInvoice}`, {
    withCredentials: true,
  })
//
export const dlMonthInvoices = async (dataObject) =>
  axios.post(`${url}/accounting/dl-month`, dataObject, {
    withCredentials: true,
  })
//
export const createHenrriInvoice = async (dataObject) =>
  axios.post(`${url}/accounting/new-henrri-invoice`, dataObject, {
    withCredentials: true,
  })
//
export const createCredit = async (dataObject) =>
  axios.post(`${url}/accounting/new-credit`, dataObject, {
    withCredentials: true,
  })
//
export const recalculateNumbers = async (monthString) =>
  axios.get(`${url}/accounting/recalculate-numbers/${monthString}`, {
    withCredentials: true,
  })
//
export const createEstimate = async (dataObject) =>
  axios.post(`${url}/accounting/new-estimate`, dataObject, {
    withCredentials: true,
  })
export const createSelfInvoice = async (dataObject) =>
  axios.post(`${url}/accounting/new-self-invoice`, dataObject, {
    withCredentials: true,
  })
//
export const deleteHenrriInvoice = async (dataObject) =>
  axios.post(`${url}/accounting/del-henrri-invoice`, dataObject, {
    withCredentials: true,
  })

//--------------------//
//requètes Mails      //
//--------------------//
//
export const mailInvoice = async (dataObject) =>
  axios.post(`${url}/mails/send-invoice`, dataObject, {
    withCredentials: true,
  })
//
export const mailCtMessage = async (dataObject) =>
  axios.post(`${url}/mails/send-ct-mail`, dataObject, {
    withCredentials: true,
  })

//--------------------//
//requètes Familles   //
//--------------------//
//
export const createFamily = async (dataObject) =>
  axios.post(`${url}/families/new`, dataObject, {
    withCredentials: true,
  })
//
export const saveFamily = async (idFamily, dataObject) =>
  axios.post(`${url}/families/save/${idFamily}`, dataObject, {
    withCredentials: true,
  })
//
export const duplicateFamily = async (idFamily, dataObject) =>
  axios.post(`${url}/families/duplicate/${idFamily}`, dataObject, {
    withCredentials: true,
  })
//
export const deleteFamily = async (idFamily) =>
  axios.post(`${url}/families/delete/${idFamily}`, {
    withCredentials: true,
  })
