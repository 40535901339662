import {
  CREATE_CART,
  CART_FROM_LSTORAGE,
  SAVE_CART,
  GET_CART_INFOS,
  EMPTY_CART,
  REMOVE_PRODUCT,
} from '../constants/actionTypes'

const cartsReducer = (
  state = { appCart: { productsCount: {} }, appCartInfos: {} },
  action
) => {
  switch (action.type) {
    case CREATE_CART:
      return { ...state, appCart: action.payload.cart }
    case CART_FROM_LSTORAGE:
      return { ...state, appCart: action.payload }
    case SAVE_CART:
      return { ...state }
    case GET_CART_INFOS:
      return { ...state, appCartInfos: action.payload }
    case EMPTY_CART:
      return { ...state, appCart: { productsCount: {} }, appCartInfos: {} }
    case REMOVE_PRODUCT:
      return {
        ...state,
        appCartInfos: {
          ...state.appCartInfos,
          products: state.appCartInfos.products.filter(
            (product) => String(product.id) !== String(action.payload)
          ),
          productsUnits: state.appCartInfos.productsUnits.filter(
            (unit) => String(unit.id_product) !== String(action.payload)
          ),
          stock: state.appCartInfos.stock.filter(
            (stock) => String(stock.id_product) !== String(action.payload)
          ),
        },
      }

    default:
      return state
  }
}

export default cartsReducer
