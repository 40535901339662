import {
  GET_SITES,
  STATS_PER_SITE,
  STATS_PER_MONTH,
  STATS_PER_YEAR,
  STATS_STOCK,
  STATS_SINCE_START,
} from '../constants/actionTypes'

const statsReducer = (state = [], action) => {
  switch (action.type) {
    case GET_SITES:
      return {
        ...state,
        sites: action.payload,
      }
    case STATS_PER_SITE:
      return {
        ...state,
        perSite: [...(state.perSite || []), action.payload.perSite],
      }
    case STATS_PER_MONTH:
      return {
        ...state,
        perMonth: [...(state.perMonth || []), action.payload.perMonth],
      }
    case STATS_PER_YEAR:
      return {
        ...state,
        perYear: [...(state.perYear || []), action.payload.perYear],
      }
    case STATS_STOCK:
      return {
        ...state,
        stock: action.payload.actualStock,
        categories: action.payload.categories,
      }
    case STATS_SINCE_START:
      return {
        ...state,
        sinceStart: action.payload.sinceStart,
      }
    default:
      return state
  }
}

export default statsReducer
