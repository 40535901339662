export const returnError = (error) => {
  if (!error.response) {
    return {
      errors: "Le serveur ne répond pas, contactez l'administrateur !",
    }
  }
  if (error.response.status > 400) {
    return {
      errors: "Erreur imprévue, contactez l'administrateur !",
    }
  } else if (!error.response.data) {
    return {
      errors: error.response,
    }
  }
  return error.response.data
}
