import React, { useEffect, useState } from 'react'
import { Navigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { getUser } from '../../actions/login'
import { useSelector } from 'react-redux'

function ProtectedRoute({ children }) {
  const loggedIn = useSelector((state) => state.login.loggedIn)
  const [isLoading, setIsLoading] = useState(true)
  const [isAuthenticated, setIsAuthenticated] = useState(loggedIn)
  const dispatch = useDispatch()

  useEffect(() => {
    if (!isAuthenticated) {
      dispatch(getUser()).then((data) => {
        if (data && data.id) {
          setIsAuthenticated(true)
        } else {
          setIsAuthenticated(false)
        }
        setIsLoading(false)
      })
    } else {
      setIsLoading(false)
    }
  }, [isAuthenticated])

  if (isLoading) {
    return <div>Loading...</div>
  } else if (isAuthenticated) {
    return children
  } else {
    return <Navigate to='/login' replace />
  }
}
export default ProtectedRoute
