import {
  LOGIN,
  LOGOUT,
  GET_USER,
  REFRESH_TOKEN,
  TOKEN_ERROR,
} from '../constants/actionTypes'

const loginReducer = (state = { user: null, loggedIn: false }, action) => {
  switch (action.type) {
    case LOGIN:
      return {
        ...state,
        user: action.payload.user,
        token: action.payload.token,
        loggedIn: true,
      }
    case LOGOUT:
      return { ...state, user: null, loggedIn: false }
    case GET_USER:
      return { ...state, user: action.payload, loggedIn: true }
    case REFRESH_TOKEN:
      return { ...state }
    case TOKEN_ERROR:
      return { user: null, loggedIn: false }
    default:
      return state
  }
}

export default loginReducer
