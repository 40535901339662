import * as api from '../api'
import {
  LOGIN,
  LOGOUT,
  GET_USER,
  REFRESH_TOKEN,
  TOKEN_ERROR,
} from '../constants/actionTypes'
import { returnError } from '../functions/returnError.js'

export const logout = () => async (dispatch) => {
  try {
    await api.logout()
    dispatch({ type: LOGOUT })
  } catch (error) {
    console.log(error.message)
  }
}

export const userLogin = (user) => async (dispatch) => {
  try {
    const { data } = await api.userLogin(user)
    dispatch({ type: LOGIN, payload: data })
    return data
  } catch (error) {
    return returnError(error)
  }
}
export const getUser = () => async (dispatch) => {
  try {
    const { data } = await api.getUser()
    dispatch({ type: GET_USER, payload: data })

    return data
  } catch (error) {
    const errorMessage = error
    return errorMessage
  }
}
export const refreshToken = () => async (dispatch) => {
  try {
    const { data } = await api.refreshToken()
    dispatch({ type: REFRESH_TOKEN, payload: data })
  } catch (error) {
    dispatch({ type: TOKEN_ERROR })
    const errorMessage = error.response.data
    console.log(errorMessage)

    return errorMessage
  }
}

/* export const authenticate = (data, next) => {
  if (typeof window !== 'undefined') {
    localStorage.setItem('jwt', JSON.stringify(data))
    next()
  }
} */
