// TOAST
const SET_TOAST_MESSAGE = 'SET_TOAST_MESSAGE'
const SET_TOAST_ERRORS = 'SET_TOAST_ERRORS'
const REFRESH_TRIGGERING = 'REFRESH_TRIGGERING'
// LOGIN
const LOGIN = 'LOGIN'
const LOGOUT = 'LOGOUT'
const GET_USER = 'GET_USER'
const REFRESH_TOKEN = 'REFRESH_TOKEN'
const TOKEN_ERROR = 'TOKEN_ERROR'

//PRODUCT
const CREATE_PRODUCT = 'CREATE_PRODUCT'
const GET_PRODUCT = 'GET_PRODUCT'
const GET_PRODUCT_FULL = 'GET_PRODUCT_FULL'
const SAVE_PRODUCT = 'SAVE_PRODUCT'
const GET_LABEL = 'GET_LABEL'
const DELETE_PRODUCT = 'DELETE_PRODUCT'
const CHANGE_LBC_STATUS = 'CHANGE_LBC_STATUS'
const GET_PRODUCT_ORDERS = 'GET_PRODUCT_ORDERS'
const GET_COLLECT_PLACE_INFOS = 'GET_COLLECT_PLACE_INFOS'
const SAVE_CO2 = 'SAVE_CO2'

//PRODUCT IMAGES
const POST_PRODUCT_IMG = 'POST_PRODUCT_IMG'
const PUT_PRODUCT_IMG = 'PUT_PRODUCT_IMG'
const DEL_PRODUCT_IMG = 'DEL_PRODUCT_IMG'

//PRODUCT OPTIONS
const CREATE_PRODUCT_OPTION = 'CREATE_PRODUCT_OPTION'
const GET_PRODUCT_OPTIONS = 'GET_PRODUCT_OPTIONS'
const GENERATE_COMBINATIONS = 'GENERATE_COMBINATIONS'
const DELETE_COMBINATION = 'DELETE_COMBINATION'
const DELETE_ALL_COMBINATIONS = 'DELETE_ALL_COMBINATIONS'

//LISTS
const GET_PRODUCTS_LIST = 'GET_PRODUCTS_LIST'
const GET_ORDERS_LIST = 'GET_ORDERS_LIST'
const EMPTY_PRODUCTS_LIST = 'EMPTY_PRODUCTS_LIST'
const GET_FAMILIES_LIST = 'GET_FAMILIES_LIST'
const GET_CUSTOMERS_LIST = 'GET_CUSTOMERS_LIST'
const GET_PROS_LIST = 'GET_PROS_LIST'
const GET_PRIVATES_LIST = 'GET_PRIVATES_LIST'

//LISTS PARAMS

const SAVE_CURRENT_PAGE = 'SAVE_CURRENT_PAGE'
const SAVE_SORT_PARAMS = 'SAVE_SORT_PARAMS'
const SAVE_FILTER = 'SAVE_FILTER'
const SAVE_ACCOUNTING_MONTH = 'SAVE_ACCOUNTING_MONTH'

//CUSTOM LISTS
const GET_CUSTOM_LIST = 'GET_CUSTOM_LIST'
const POST_CUSTOM_LIST_ITEM = 'POST_CUSTOM_LIST_ITEM'
const DELETE_CUSTOM_LIST_ITEM = 'DELETE_CUSTOM_LIST_ITEM'
const PUT_CUSTOM_LIST_ITEM = 'PUT_CUSTOM_LIST_ITEM'

//CART
const CREATE_CART = 'CREATE_CART'
const CART_FROM_LSTORAGE = 'CART_FROM_LSTORAGE'
const SAVE_CART = 'SAVE_CART'
const GET_CART_INFOS = 'GET_CART_INFOS'
const EMPTY_CART = 'EMPTY_CART'
const REMOVE_PRODUCT = 'REMOVE_PRODUCT'

//CUSTOMERS
const CREATE_CUSTOMER = 'CREATE_CUSTOMER'
const GET_CUSTOMER_ADDRESSES = 'GET_CUSTOMER_ADDRESSES'
const GET_CUSTOMER = 'GET_CUSTOMER'
const CREATE_ADDRESS = 'CREATE_ADDRESS'
const SAVE_CUSTOMER = 'SAVE_CUSTOMER'
const SAVE_ADDRESS = 'SAVE_ADDRESS'
const CHANGE_CUSTOMER_TYPE = 'CHANGE_CUSTOMER_TYPE'
const GET_SOURCE_SALES = 'GET_SOURCE_SALES'

//ORDERS
const GET_ORDER = 'GET_ORDER'
const CHANGE_STATE = 'CHANGE_STATE'
const CREATE_ORDER = 'CREATE_ORDER'
const GET_ORDER_OPTIONS = 'GET_ORDER_OPTIONS'
const SEND_ORDER_CONF = 'SEND_ORDER_CONF'
const CHANGE_CUSTOMER = 'CHANGE_CUSTOMER'
const CHANGE_PAYMENT = 'CHANGE_PAYMENT'
const CREATE_PAYMENT = 'CREATE_PAYMENT'
const DELETE_PAYMENT = 'DELETE_PAYMENT'
const CANCEL_ORDER = 'CANCEL_ORDER'
const CREATE_CART_RULE = 'CREATE_CART_RULE'

//CUSTOMER THREADS
const CREATE_CT_THREAD = 'CREATE_CT_THREAD'
const CREATE_CT_MESSAGE = 'CREATE_CT_MESSAGE'
const CREATE_ORDER_CT_MESSAGE = 'CREATE_ORDER_CT_MESSAGE'
const GET_CT_LIST = 'GET_CT_LIST'
const UPDATE_THREAD = 'UPDATE_THREAD'
const MARK_MSGS_READ = 'MARK_MSGS_READ'
const MARK_MSG_NOT_READ = 'MARK_MSG_NOT_READ'
const SET_CT_FILTER = 'SET_CT_FILTER'
const CLEAR_CT_FILTER = 'CLEAR_CT_FILTER'

//STATS
const GET_SITES = 'GET_SITES'
const STATS_PER_SITE = 'STATS_PER_SITE'
const STATS_PER_MONTH = 'STATS_PER_MONTH'
const STATS_PER_YEAR = 'STATS_PER_YEAR'
const STATS_STOCK = 'STATS_STOCK'
const STATS_SINCE_START = 'STATS_SINCE_START'

//ACCOUNTING
const GET_MONTH_INVOICES = 'GET_MONTH_INVOICES'
const GET_INVOICE = 'GET_INVOICE'
const CREATE_HENRRI_INVOICE = 'CREATE_HENRRI_INVOICE'
const DL_MONTH_INVOICES = 'DL_MONTH_INVOICES'
const NEW_CREDIT = 'NEW_CREDIT'
const RECALCULATE_NUMBERS = 'RECALCULATE_NUMBERS'
const NEW_ESTIMATE = 'NEW_ESTIMATE'
const NEW_SELF_INVOICE = 'NEW_SELF_INVOICE'
const DELETE_HENRRI_INVOICE = 'DELETE_HENRRI_INVOICE'

//MAILS
const MAIL_INVOICE = 'MAIL_INVOICE'
const MAIL_CT = 'MAIL_CT'

//FAMILIES
const CREATE_FAMILY = 'CREATE_FAMILY'
const SAVE_FAMILY = 'SAVE_FAMILY'
const DUPLICATE_FAMILY = 'DUPLICATE_FAMILY'
const DELETE_FAMILY = 'DELETE_FAMILY'
export {
  //TOAST
  SET_TOAST_MESSAGE,
  SET_TOAST_ERRORS,
  REFRESH_TRIGGERING,

  // LOGIN
  LOGIN,
  LOGOUT,
  GET_USER,
  REFRESH_TOKEN,
  TOKEN_ERROR,

  //PRODUCT
  CREATE_PRODUCT,
  GET_PRODUCT,
  GET_PRODUCT_FULL,
  SAVE_PRODUCT,
  GET_LABEL,
  DELETE_PRODUCT,
  CHANGE_LBC_STATUS,
  GET_PRODUCT_ORDERS,
  GET_COLLECT_PLACE_INFOS,
  SAVE_CO2,

  //PRODUCT IMAGES
  POST_PRODUCT_IMG,
  PUT_PRODUCT_IMG,
  DEL_PRODUCT_IMG,

  //PRODUCT OPTIONS
  CREATE_PRODUCT_OPTION,
  GET_PRODUCT_OPTIONS,
  GENERATE_COMBINATIONS,
  DELETE_COMBINATION,
  DELETE_ALL_COMBINATIONS,

  //LISTS
  GET_PRODUCTS_LIST,
  EMPTY_PRODUCTS_LIST,
  GET_ORDERS_LIST,
  GET_FAMILIES_LIST,
  GET_CUSTOMERS_LIST,
  GET_PROS_LIST,
  GET_PRIVATES_LIST,

  //LISTS PARAMS
  SAVE_CURRENT_PAGE,
  SAVE_SORT_PARAMS,
  SAVE_FILTER,
  SAVE_ACCOUNTING_MONTH,

  //CUSTOM LISTS
  GET_CUSTOM_LIST,
  POST_CUSTOM_LIST_ITEM,
  DELETE_CUSTOM_LIST_ITEM,
  PUT_CUSTOM_LIST_ITEM,

  //CART
  CREATE_CART,
  CART_FROM_LSTORAGE,
  SAVE_CART,
  GET_CART_INFOS,
  EMPTY_CART,
  REMOVE_PRODUCT,

  //CUSTOMERS
  CREATE_CUSTOMER,
  GET_CUSTOMER_ADDRESSES,
  GET_CUSTOMER,
  CREATE_ADDRESS,
  CHANGE_CUSTOMER,
  SAVE_CUSTOMER,
  SAVE_ADDRESS,
  CHANGE_CUSTOMER_TYPE,
  GET_SOURCE_SALES,

  //ORDERS
  GET_ORDER,
  CHANGE_STATE,
  CREATE_ORDER,
  GET_ORDER_OPTIONS,
  SEND_ORDER_CONF,
  CHANGE_PAYMENT,
  CREATE_PAYMENT,
  DELETE_PAYMENT,
  CANCEL_ORDER,
  CREATE_CART_RULE,

  //CUSTOMER THREADS
  CREATE_CT_THREAD,
  CREATE_CT_MESSAGE,
  CREATE_ORDER_CT_MESSAGE,
  GET_CT_LIST,
  UPDATE_THREAD,
  MARK_MSGS_READ,
  MARK_MSG_NOT_READ,
  SET_CT_FILTER,
  CLEAR_CT_FILTER,

  //STATS
  GET_SITES,
  STATS_PER_SITE,
  STATS_PER_MONTH,
  STATS_PER_YEAR,
  STATS_STOCK,
  STATS_SINCE_START,

  //ACCOUNTING
  GET_MONTH_INVOICES,
  GET_INVOICE,
  CREATE_HENRRI_INVOICE,
  DL_MONTH_INVOICES,
  NEW_CREDIT,
  RECALCULATE_NUMBERS,
  NEW_ESTIMATE,
  NEW_SELF_INVOICE,
  DELETE_HENRRI_INVOICE,

  //MAILS
  MAIL_INVOICE,
  MAIL_CT,

  //FAMILIES
  CREATE_FAMILY,
  SAVE_FAMILY,
  DUPLICATE_FAMILY,
  DELETE_FAMILY,
}
