import {
  CREATE_PRODUCT,
  GET_PRODUCT,
  GET_PRODUCT_FULL,
  POST_PRODUCT_IMG,
  PUT_PRODUCT_IMG,
  DEL_PRODUCT_IMG,
  SAVE_PRODUCT,
  GET_PRODUCTS_LIST,
  GET_LABEL,
  DELETE_PRODUCT,
  EMPTY_PRODUCTS_LIST,
  CREATE_PRODUCT_OPTION,
  GET_PRODUCT_OPTIONS,
  GENERATE_COMBINATIONS,
  DELETE_COMBINATION,
  DELETE_ALL_COMBINATIONS,
  CHANGE_LBC_STATUS,
  GET_PRODUCT_ORDERS,
  GET_COLLECT_PLACE_INFOS,
  SAVE_CO2,
} from '../constants/actionTypes'

const productsReducer = (state = {}, action) => {
  switch (action.type) {
    case CREATE_PRODUCT:
      return { ...state, list: null }
    case GET_PRODUCT:
      return { ...state, product: action.payload.product }
    case GET_PRODUCT_FULL:
      return { ...state, product: action.payload }
    case POST_PRODUCT_IMG:
      return { ...state }
    case DEL_PRODUCT_IMG:
      return { ...state }
    case PUT_PRODUCT_IMG:
      return { ...state }
    case SAVE_PRODUCT:
      return { ...state, list: null }
    case GET_PRODUCTS_LIST:
      return { ...state, list: action.payload }
    case GET_LABEL:
      return { ...state, label: action.payload }
    case DELETE_PRODUCT:
      return { ...state, list: null, product: null }
    case EMPTY_PRODUCTS_LIST:
      return { ...state, list: null }
    case CREATE_PRODUCT_OPTION:
      state.product.product_options.push(action.payload.newOption)
      const newProductOptionValues = state.product.product_option_values.concat(
        action.payload.newOptionValues
      )
      return {
        ...state,
        product: {
          ...state.product,
          product_options: state.product.product_options,
          product_option_values: newProductOptionValues,
        },
      }
    case GET_PRODUCT_OPTIONS:
      return {
        ...state,
        product: {
          ...state.product,
          product_options: action.payload.options,
          product_option_values: action.payload.optionsValues,
          combinations: [],
          combinations_stocks: [],
          product: {
            ...state.product.product,
            associations: {
              ...state.product.product.associations,
              combinations: [],
              product_option_values: [],
            },
          },
        },
      }
    case GENERATE_COMBINATIONS:
      const productAssociations = state.product.product.associations
      const newCombinationsArray = state.product.combinations.concat(
        action.payload.newCombinations
      )
      const newCombinationsStocksArray =
        state.product.combinations_stocks.concat(action.payload.newStocks)

      //on Crée un nouveau Array avec les anciennes id et les nouvelles pour les associations du product dans le state
      let newProductCombinationsIds
      const newCombinationsIds = action.payload.newCombinations.map(
        (combination) => ({ id: String(combination.id) })
      )
      if (productAssociations.combinations) {
        newProductCombinationsIds =
          productAssociations.combinations.concat(newCombinationsIds)
      } else {
        newProductCombinationsIds = newCombinationsIds
      }

      // On ajoute les nouvelles product_options_values au produit stocké dans redux
      const mappedOptionsValues =
        productAssociations.product_option_values || []
      const seenOptionsValues = productAssociations.product_option_values || []
      const flattenedOptionsValues = action.payload.newCombinations.flatMap(
        (combination) => {
          const combinationOptionsValues =
            combination.associations.product_option_values
          for (
            let index = 0;
            index < combinationOptionsValues.length;
            index++
          ) {
            const element = combinationOptionsValues[index]

            if (!seenOptionsValues.some((item) => item.id === element.id)) {
              mappedOptionsValues.push(element)
              seenOptionsValues.push(element)
            }
          }
          return mappedOptionsValues
        }
      )
      console.log(seenOptionsValues)
      return {
        ...state,
        product: {
          ...state.product,
          product: {
            ...state.product.product,
            associations: {
              ...state.product.product.associations,
              combinations: newProductCombinationsIds,
              product_option_values: mappedOptionsValues,
            },
          },
          combinations: newCombinationsArray,
          combinations_stocks: newCombinationsStocksArray,
        },
      }
    case DELETE_COMBINATION:
      const objectFormatter = (object) => {
        let array = []
        Object.keys(object).map((key) => {
          array.push(object[key])
          return null
        })
        return array
      }
      const combinationDeleted = state.product.combinations.find(
        (combination) =>
          String(combination.id) === String(action.payload.idCombination)
      )

      const productOptionValuesToCheck = objectFormatter(
        combinationDeleted.associations.product_option_values
      ).map((optionValue) => optionValue.id)
      const othersCombinations = state.product.combinations.filter(
        (item) => String(item.id) !== String(action.payload.idCombination)
      )
      const valuesUsedInOthersCombinations = othersCombinations.flatMap(
        (combination) => {
          let values = []
          const optionValuesArray = objectFormatter(
            combination.associations.product_option_values
          )
          for (let index = 0; index < optionValuesArray.length; index++) {
            const element = optionValuesArray[index]
            values.push(element.id)
          }
          return values
        }
      )

      const productOptionValuesToDelete = productOptionValuesToCheck.map(
        (id) => {
          if (
            valuesUsedInOthersCombinations.some(
              (value) => String(value) === String(id)
            )
          )
            return null
          else return String(id)
        }
      )

      const toDelete = productOptionValuesToDelete.filter((id) => id !== null)
      return {
        ...state,
        product: {
          ...state.product,
          combinations: othersCombinations,
          combinations_stocks: state.product.combinations_stocks.filter(
            (item) => String(item.id) !== String(action.payload.idStock)
          ),
          product: {
            ...state.product.product,
            associations: {
              ...state.product.product.associations,
              combinations:
                state.product.product.associations.combinations.filter(
                  (item) =>
                    String(item.id) !== String(action.payload.idCombination)
                ),
              product_option_values:
                state.product.product.associations.product_option_values.filter(
                  (obj) => !toDelete.includes(String(obj.id))
                ),
            },
          },
        },
      }
    case DELETE_ALL_COMBINATIONS:
      delete state.product.combinations
      delete state.product.combinations_stocks
      delete state.product.product_options
      delete state.product.product_option_values
      delete state.product.product.associations.combinations
      delete state.product.product.associations.product_option_values

      return {
        ...state,
        product: {
          ...state.product,
          stock: state.product.stock.filter(
            (stock) => stock.id_product_attribute !== '0'
          ),
          product: {
            ...state.product.product,
            cache_default_attribute: '0',
            id_default_combination: '0',
            associations: {
              ...state.product.product.associations,
              stock_availables:
                state.product.product.associations.stock_availables.filter(
                  (stock) => stock.id_product_attribute !== '0'
                ),
            },
          },
        },
      }
    case CHANGE_LBC_STATUS:
      let newStatus = state.product.lbcExport
      let newExports = null
      const idProduct = action.payload.idProduct
      if (
        state.product?.product &&
        String(state.product.product.id) === idProduct
      ) {
        state.product.lbcExport[0].status = action.payload.newStatus
        state.product.lbcExport[0].url = action.payload.newUrl
      }
      if (state.list) {
        const exportIndex = state.list.lbcExports.findIndex(
          (exp) => String(exp.id_product) === idProduct
        )
        console.log('index :' + exportIndex)
        state.list.lbcExports[exportIndex].status = action.payload.newStatus
        state.list.lbcExports[exportIndex].url = action.payload.newUrl

        newExports = state.list.lbcExports

        return {
          ...state,
          product: {
            ...state.product,
            lbcExport: newStatus,
          },
          list: {
            ...state.list,
            lbcExports: newExports,
          },
        }
      }
      return {
        ...state,
        product: {
          ...state.product,
          lbcExport: newStatus,
        },
      }
    case GET_PRODUCT_ORDERS:
      return {
        ...state,
        product: {
          ...state.product,
          orders: action.payload,
        },
      }
    case GET_COLLECT_PLACE_INFOS:
      console.log(action.payload)
      return {
        ...state,
        product: {
          ...state.product,
          collectPlaceInfos: action.payload.collectPlace,
        },
      }
    case SAVE_CO2:
      return {
        ...state,
        product: { ...state.product, productCo2: action.payload.productCo2 },
      }
    default:
      return state
  }
}

export default productsReducer
/* state.product.associations.images.push({
        id: action.payload.id_image,
      }) 
state.product.associations.images.filter(
        (image) => image.id !== action.payload
      )*/
