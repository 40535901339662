import {
  CREATE_FAMILY,
  DELETE_FAMILY,
  DUPLICATE_FAMILY,
  GET_FAMILIES_LIST,
  SAVE_FAMILY,
} from '../constants/actionTypes'

const familiesReducer = (state = {}, action) => {
  let newList
  if (state.list) newList = [...state.list]
  switch (action.type) {
    case GET_FAMILIES_LIST:
      return {
        ...state,
        list: action.payload.list,
        inies: action.payload.inies,
        categories: action.payload.categories,
        features: action.payload.features,
        featuresValues: action.payload.featuresValues,
      }
    case CREATE_FAMILY:
      newList.push(action.payload.family)
      return {
        ...state,
        list: newList,
      }
    case SAVE_FAMILY:
      const updatedFamilyId = action.payload.family.id
      const familyIndex = state.list.findIndex(
        (fam) => fam.id === updatedFamilyId
      )
      newList[familyIndex] = action.payload.family
      return {
        ...state,
        list: newList,
      }
    case DUPLICATE_FAMILY:
      newList.push(action.payload.family)
      return {
        ...state,
        list: newList,
      }
    case DELETE_FAMILY:
      const filteredNewList = newList.filter(
        (fam) => fam.id !== action.payload.deletedId
      )
      return {
        ...state,
        list: filteredNewList,
      }
    default:
      return state
  }
}

export default familiesReducer
