import React, { useEffect, useState, useRef } from 'react'
import { Routes, Route, useNavigate } from 'react-router-dom'

import ProtectedRoute from '../ProtectedRoute/ProtectedRoute'
import { useDispatch, useSelector } from 'react-redux'
import { getUser, refreshToken } from '../../actions/login'
import Spinner from '../Spinner/Spinner'

const App = React.lazy(() => import('../../App'))
const Login = React.lazy(() => import('../../pages/Login'))
const Homepage = React.lazy(() => import('../../pages/Homepage'))
const NouveauProduit = React.lazy(() => import('../../pages/NouveauProduit'))
const Produit = React.lazy(() => import('../../pages/Produit'))
const ScanProduit = React.lazy(() => import('../../pages/ScanProduit'))
const TestLbc = React.lazy(() => import('../../pages/TestLbc'))
const ErrorPage = React.lazy(() => import('../../pages/ErrorPage'))
const ListeProduits = React.lazy(() => import('../../pages/ListeProduits'))
const ListeCommandes = React.lazy(() => import('../../pages/ListeCommandes'))
const ListeCustom = React.lazy(() => import('../../pages/ListeCustom'))
const Commande = React.lazy(() => import('../../pages/Commande'))
const EtiquetteProduit = React.lazy(() =>
  import('../../pages/EtiquetteProduit')
)
const PanierApp = React.lazy(() => import('../../pages/PanierApp'))
const Sav = React.lazy(() => import('../../pages/Sav'))
const Statistiques = React.lazy(() => import('../../pages/Statistiques'))
const Compta = React.lazy(() => import('../../pages/Compta'))
const Facture = React.lazy(() => import('../../pages/Facture'))
const FacturesMois = React.lazy(() => import('../../pages/FacturesMois'))
const Particuliers = React.lazy(() => import('../../pages/Particuliers'))
const Professionnels = React.lazy(() => import('../../pages/Professionnels'))
const FicheClient = React.lazy(() => import('../../pages/FicheClient'))
const FicheFournisseur = React.lazy(() =>
  import('../../pages/FicheFournisseur')
)
const RecapFournisseur = React.lazy(() =>
  import('../../pages/RecapFournisseur')
)
const DonneesCo2 = React.lazy(() => import('../../pages/DonneesCo2'))
const Familles = React.lazy(() => import('../../pages/Familles'))

function AppRoutes() {
  const loggedIn = useSelector((state) => state.login.loggedIn)
  const [isAuthenticated, setIsAuthenticated] = useState(loggedIn)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const firstRender = useRef(true)
  useEffect(() => {
    if (firstRender.current) {
      dispatch(getUser()).then((data) => {
        if (data && data.id) {
          setIsAuthenticated(true)
          firstRender.current = false
        } else {
          setIsAuthenticated(false)
        }
      })
    }
    if (isAuthenticated === true || loggedIn === true) {
      let intervalId = setInterval(() => {
        dispatch(refreshToken()).then((data) => {
          if (!data) {
            setIsAuthenticated(true)
          } else {
            setIsAuthenticated(false)
            navigate('/login')
          }
        })
      }, 1000 * 60)
      return () => clearInterval(intervalId)
    }
  }, [isAuthenticated, loggedIn])

  return (
    <Routes>
      <Route path='/etiquette-produit' element={<EtiquetteProduit />} />
      <Route
        path='/'
        element={
          <React.Suspense
            fallback={
              <Spinner message="Chargement de l'application, veuillez patienter..." />
            }
          >
            <App />
          </React.Suspense>
        }
        errorElement={<ErrorPage />}
      >
        <Route path='login' element={<Login />} />
        <Route path='/testlbc' element={<TestLbc />} />
        <Route
          path=''
          element={
            <ProtectedRoute>
              <Homepage />
            </ProtectedRoute>
          }
          index
        />
        <Route
          path='/nouveau-produit'
          element={
            <ProtectedRoute>
              <NouveauProduit />
            </ProtectedRoute>
          }
        />
        <Route
          path='/panier-app'
          element={
            <ProtectedRoute>
              <PanierApp />
            </ProtectedRoute>
          }
        />
        <Route
          path='/scan-produit'
          element={
            <ProtectedRoute>
              <ScanProduit />
            </ProtectedRoute>
          }
        />
        <Route
          path='/donnees-co2'
          element={
            <ProtectedRoute>
              <DonneesCo2 />
            </ProtectedRoute>
          }
        />
        <Route
          path='/familles'
          element={
            <ProtectedRoute>
              <Familles />
            </ProtectedRoute>
          }
        />
        <Route
          path='/produit/:id'
          element={
            <React.Suspense
              fallback={
                <Spinner message="Chargement de l'application, veuillez patienter..." />
              }
            >
              <ProtectedRoute>
                <Produit />
              </ProtectedRoute>
            </React.Suspense>
          }
        />
        <Route
          path='/liste-produits/'
          element={
            <React.Suspense
              fallback={
                <Spinner message="Chargement de l'application, veuillez patienter..." />
              }
            >
              <ProtectedRoute>
                <ListeProduits />
              </ProtectedRoute>
            </React.Suspense>
          }
        />
        <Route
          path='/particuliers/'
          element={
            <React.Suspense
              fallback={
                <Spinner message="Chargement de l'application, veuillez patienter..." />
              }
            >
              <ProtectedRoute>
                <Particuliers />
              </ProtectedRoute>
            </React.Suspense>
          }
        />
        <Route
          path='/professionnels/'
          element={
            <React.Suspense
              fallback={
                <Spinner message="Chargement de l'application, veuillez patienter..." />
              }
            >
              <ProtectedRoute>
                <Professionnels />
              </ProtectedRoute>
            </React.Suspense>
          }
        />
        <Route
          path='/fiche-client/:idCustomer'
          element={
            <React.Suspense
              fallback={
                <Spinner message="Chargement de l'application, veuillez patienter..." />
              }
            >
              <ProtectedRoute>
                <FicheClient />
              </ProtectedRoute>
            </React.Suspense>
          }
        />
        <Route
          path='/fiche-fournisseur/:idCustomer'
          element={
            <React.Suspense
              fallback={
                <Spinner message="Chargement de l'application, veuillez patienter..." />
              }
            >
              <ProtectedRoute>
                <FicheFournisseur />
              </ProtectedRoute>
            </React.Suspense>
          }
        />
        <Route
          path='/recap-fournisseur/:idCustomer/:year'
          element={
            <React.Suspense
              fallback={
                <Spinner message="Chargement de l'application, veuillez patienter..." />
              }
            >
              <ProtectedRoute>
                <RecapFournisseur />
              </ProtectedRoute>
            </React.Suspense>
          }
        />
        <Route
          path='/liste-commandes/'
          element={
            <React.Suspense
              fallback={
                <Spinner message="Chargement de l'application, veuillez patienter..." />
              }
            >
              <ProtectedRoute>
                <ListeCommandes />
              </ProtectedRoute>
            </React.Suspense>
          }
        />
        <Route
          path='/sav/'
          element={
            <React.Suspense
              fallback={
                <Spinner message="Chargement de l'application, veuillez patienter..." />
              }
            >
              <ProtectedRoute>
                <Sav />
              </ProtectedRoute>
            </React.Suspense>
          }
        />
        <Route
          path='/statistiques/'
          element={
            <React.Suspense
              fallback={
                <Spinner message="Chargement de l'application, veuillez patienter..." />
              }
            >
              <ProtectedRoute>
                <Statistiques />
              </ProtectedRoute>
            </React.Suspense>
          }
        />
        <Route
          path='/compta/'
          element={
            <React.Suspense
              fallback={
                <Spinner message="Chargement de l'application, veuillez patienter..." />
              }
            >
              <ProtectedRoute>
                <Compta />
              </ProtectedRoute>
            </React.Suspense>
          }
        />
        <Route
          path='/compta/facture/:idInvoice'
          element={
            <React.Suspense
              fallback={
                <Spinner message="Chargement de l'application, veuillez patienter..." />
              }
            >
              <ProtectedRoute>
                <Facture />
              </ProtectedRoute>
            </React.Suspense>
          }
        />
        <Route
          path='/compta/factures-mois/:infosString'
          element={
            <React.Suspense
              fallback={
                <Spinner message="Chargement de l'application, veuillez patienter..." />
              }
            >
              <ProtectedRoute>
                <FacturesMois />
              </ProtectedRoute>
            </React.Suspense>
          }
        />
        <Route
          path='/liste-custom/:wsResource'
          element={
            <React.Suspense
              fallback={
                <Spinner message="Chargement de l'application, veuillez patienter..." />
              }
            >
              <ProtectedRoute>
                <ListeCustom />
              </ProtectedRoute>
            </React.Suspense>
          }
        />
        <Route
          path='/commande/:id'
          element={
            <React.Suspense
              fallback={
                <Spinner message="Chargement de l'application, veuillez patienter..." />
              }
            >
              <ProtectedRoute>
                <Commande />
              </ProtectedRoute>
            </React.Suspense>
          }
        />
      </Route>
    </Routes>
  )
}

export default AppRoutes
