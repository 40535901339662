import {
  SET_TOAST_MESSAGE,
  SET_TOAST_ERRORS,
  REFRESH_TRIGGERING,
} from '../constants/actionTypes'

const toastReducer = (
  state = { message: '', errors: '', triggering: false },
  action
) => {
  switch (action.type) {
    case SET_TOAST_MESSAGE:
      if (!action.payload.triggering)
        return { ...state, message: action.payload.message, errors: '' }
      return {
        ...state,
        message: action.payload.message,
        errors: '',
        triggering: true,
      }
    case SET_TOAST_ERRORS:
      if (!action.payload.triggering)
        return { ...state, message: '', errors: action.payload.errors }
      return {
        ...state,
        message: '',
        errors: action.payload.errors,
        triggering: true,
      }
    case REFRESH_TRIGGERING:
      return { ...state, triggering: false }
    default:
      return state
  }
}

export default toastReducer
