import React from 'react'

function Spinner({ message, notFull }) {
  return notFull ? (
    <div className='container-fuild d-flex align-items-center h-25'>
      <div className='text-center'>
        <div className='spinner-border' role='status'>
          <span className='visually-hidden'>Loading...</span>
        </div>
        <div className='text-center'>{message}</div>
      </div>
    </div>
  ) : (
    <div className='container-fuild min-vh-100' style={{ marginTop: '5em' }}>
      <div className='text-center'>
        <div className='spinner-border' role='status'>
          <span className='visually-hidden'>Loading...</span>
        </div>
        <div className='text-center'>{message}</div>
      </div>
    </div>
  )
}

export default Spinner
