import {
  GET_ORDERS_LIST,
  GET_ORDER,
  CHANGE_STATE,
  CREATE_CT_THREAD,
  CREATE_ORDER_CT_MESSAGE,
  CREATE_ORDER,
  GET_ORDER_OPTIONS,
  SEND_ORDER_CONF,
  CHANGE_CUSTOMER,
  CHANGE_PAYMENT,
  CREATE_PAYMENT,
  DELETE_PAYMENT,
  CANCEL_ORDER,
  CREATE_CART_RULE,
  NEW_CREDIT,
  NEW_ESTIMATE,
} from '../constants/actionTypes'

const ordersReducer = (state = [], action) => {
  switch (action.type) {
    case GET_ORDERS_LIST:
      return { ...state, list: action.payload }
    case GET_ORDER:
      return { ...state, order: action.payload }
    case CHANGE_STATE:
    case CANCEL_ORDER:
      console.log(action.payload)
      let newOrder = null
      let newList = null
      const idOrder = action.payload.history.id_order
      if (state.order?.order && String(state.order.order.id) === idOrder)
        newOrder = {
          ...state.order.order,
          current_state: String(action.payload.history.id_order_state),
        }
      if (state.list?.list) {
        const orderIndex = state.list.list.findIndex(
          (order) => String(order.id) === idOrder
        )
        state.list.list[orderIndex].current_state = String(
          action.payload.history.id_order_state
        )
        newList = state.list.list
      }
      if (action.type === CANCEL_ORDER && action.payload?.superInvoice) {
        if (
          !state.order.superInvoices.find(
            (sInvoice) =>
              String(sInvoice.id) === String(action.payload.superInvoice.id)
          )
        )
          state.order.superInvoices.push(action.payload?.superInvoice)
      }
      return {
        ...state,
        order: {
          ...state.order,
          order: newOrder,
        },
        list: {
          ...state.list,
          list: newList,
        },
      }

    case CREATE_CT_THREAD:
      state.order.messages.push(action.payload.ctMessage)
      return {
        ...state,
        order: {
          ...state.order,
          messages: state.order.messages,
          thread: action.payload.ctThread,
        },
      }
    case CREATE_ORDER_CT_MESSAGE:
      state.order.messages.push(action.payload.ctMessage)
      return {
        ...state,
        order: { ...state.order, messages: state.order.messages },
      }
    case CREATE_ORDER:
      return { ...state, list: null }
    case GET_ORDER_OPTIONS:
      return { ...state, orderOptions: action.payload }
    case SEND_ORDER_CONF:
      return { ...state }
    case CHANGE_CUSTOMER:
      return {
        ...state,
        order: {
          ...state.order,
          order: action.payload.order,
          customer: [action.payload.customer],
          address: action.payload.address,
        },
      }
    case CHANGE_PAYMENT:
      const newPaymentList = state.order.orderPayment.filter(
        (payment) => String(payment.id) !== String(action.payload.payment.id)
      )
      newPaymentList.push(action.payload.payment)
      console.log(newPaymentList)
      return {
        ...state,
        order: {
          ...state.order,
          orderPayment: newPaymentList,
        },
      }
    case CREATE_PAYMENT:
      if (Object.keys(action.payload.history).length > 0) {
        let newOrder = null
        let newList = null
        const idOrder = action.payload.history.id_order
        if (state.order?.order && String(state.order.order.id) === idOrder)
          newOrder = {
            ...state.order.order,
            current_state: String(action.payload.history.id_order_state),
          }
        if (state.list?.list) {
          const orderIndex = state.list.list.findIndex(
            (order) => String(order.id) === idOrder
          )
          state.list.list[orderIndex].current_state = String(
            action.payload.history.id_order_state
          )
          newList = state.list.list
        }
        if (
          !state.order.orderPayment.find(
            (payment) =>
              String(payment.id) === String(action.payload.payment.id)
          )
        )
          state.order.orderPayment.push(action.payload.payment)

        state.order.superInvoices.push(action.payload.superInvoice)
        return {
          ...state,
          order: {
            ...state.order,
            order: newOrder,
          },
          list: {
            ...state.list,
            list: newList,
          },
        }
      } else {
        if (
          !state.order.orderPayment.find(
            (payment) =>
              String(payment.id) === String(action.payload.payment.id)
          )
        )
          state.order.orderPayment.push(action.payload.payment)
        return { ...state }
      }
    case DELETE_PAYMENT:
      const newPaymentListDel = state.order.orderPayment.filter(
        (payment) => String(payment.id) !== String(action.payload.idPayment)
      )
      return {
        ...state,
        order: {
          ...state.order,
          orderPayment: newPaymentListDel,
        },
      }
    case CREATE_CART_RULE:
      return {
        ...state,
        order: {
          ...state.order,
          cartRule: [action.payload.cartRule],
          orderCartRule: [action.payload.orderCartRule],
        },
      }
    case NEW_CREDIT:
      state.order.orderPayment.push(action.payload.payment)
      state.order.superInvoices.push(action.payload.superInvoice)
      return {
        ...state,
        order: {
          ...state.order,
          orderPayment: state.order.orderPayment,
          superInvoices: state.order.superInvoices,
        },
      }
    case NEW_ESTIMATE:
      state.order.superInvoices.push(action.payload.superInvoice)
      return {
        ...state,
        order: {
          ...state.order,
          superInvoices: state.order.superInvoices,
        },
      }
    default:
      return state
  }
}

export default ordersReducer
