import {
  SAVE_ACCOUNTING_MONTH,
  SAVE_CURRENT_PAGE,
  SAVE_FILTER,
  SAVE_SORT_PARAMS,
  NEW_SELF_INVOICE,
} from '../constants/actionTypes'

const listsparamsReducer = (
  state = {
    products: {},
    orders: {},
    privates: {},
    pros: {},
    customerThreads: {},
    families: {},
    AccountingRecapMonth: '',
  },
  action
) => {
  switch (action.type) {
    case SAVE_ACCOUNTING_MONTH:
      return {
        ...state,
        AccountingRecapMonth: action.payload,
      }
    case NEW_SELF_INVOICE:
      return {
        ...state,
        AccountingRecapMonth: '',
      }
    case SAVE_CURRENT_PAGE:
      return {
        ...state,
        [action.payload.list]: {
          ...state[action.payload.list],
          currentPage: action.payload.pageNumber,
          itemOffset: action.payload.itemOffset,
        },
      }
    case SAVE_SORT_PARAMS:
      return {
        ...state,
        [action.payload.list]: {
          ...state[action.payload.list],
          sortParams: {
            property: action.payload.property,
            propertyType: action.payload.propertyType,
          },
        },
      }
    case SAVE_FILTER:
      return {
        ...state,
        [action.payload.list]: {
          ...state[action.payload.list],
          filterValues: action.payload,
        },
      }

    default:
      return state
  }
}

export default listsparamsReducer
