import {
  GET_CT_LIST,
  UPDATE_THREAD,
  MARK_MSGS_READ,
  MARK_MSG_NOT_READ,
  CREATE_CT_MESSAGE,
  SET_CT_FILTER,
  CLEAR_CT_FILTER,
} from '../constants/actionTypes'

const customerThreadsReducer = (state = { filter: {} }, action) => {
  switch (action.type) {
    case GET_CT_LIST:
      return { ...state, list: action.payload }
    case UPDATE_THREAD:
      const index = state.list.list.findIndex(
        (thread) => String(thread.id) === String(action.payload.thread.id)
      )

      if (index !== -1) {
        const newList = [...state.list.list]
        newList.splice(index, 1, action.payload.thread)
        return {
          ...state,
          list: {
            ...state.list,
            list: newList,
          },
        }
      } else {
        const newList = [...state.list.list]
        newList.unshift(action.payload.thread)
        return {
          ...state,
          list: {
            ...state.list,
            list: newList,
          },
        }
      }
    case MARK_MSG_NOT_READ:
      const messageIndex = state.list.messages.findIndex(
        (message) => String(message.id) === String(action.payload.newMessage.id)
      )

      const newMessagesListR = [...state.list.messages]
      newMessagesListR.splice(messageIndex, 1, action.payload.newMessage)
      return {
        ...state,
        list: {
          ...state.list,
          messages: newMessagesListR,
        },
      }

    case MARK_MSGS_READ:
      const newMessagesList = [...state.list.messages]
      for (let index = 0; index < action.payload.messages.length; index++) {
        const element = action.payload.messages[index]
        const indexToUpdate = newMessagesList.findIndex(
          (message) => String(message.id) === String(element.id)
        )
        newMessagesList.splice(indexToUpdate, 1, element)
      }
      return {
        ...state,
        list: {
          ...state.list,
          messages: newMessagesList,
        },
      }
    case CREATE_CT_MESSAGE:
      const newMessageIndex = state.list.messages.findIndex(
        (message) => String(message.id) === String(action.payload.ctMessage.id)
      )

      const newMessagesListC = [...state.list.messages]
      newMessagesListC.splice(newMessageIndex, 1, action.payload.ctMessage)
      return {
        ...state,
        list: {
          ...state.list,
          messages: newMessagesListC,
        },
      }
    case SET_CT_FILTER:
      return { ...state, filter: action.payload }
    case CLEAR_CT_FILTER:
      return { ...state, filter: {} }
    default:
      return state
  }
}

export default customerThreadsReducer
