import {
  CREATE_CUSTOMER,
  GET_CUSTOMERS_LIST,
  GET_PROS_LIST,
  GET_CUSTOMER_ADDRESSES,
  CREATE_ADDRESS,
  GET_CUSTOMER,
  SAVE_CUSTOMER,
  SAVE_ADDRESS,
  GET_PRIVATES_LIST,
  CHANGE_CUSTOMER_TYPE,
  GET_SOURCE_SALES,
  NEW_SELF_INVOICE,
} from '../constants/actionTypes'

const customersReducer = (
  state = { customers: [], addresses: [], pros: [], privates: [], cards: [] },
  action
) => {
  switch (action.type) {
    case CREATE_CUSTOMER:
      if (action.payload.isPro === '')
        state.customers.push(action.payload.newCustomer)
      if (action.payload.isPro === 'true' && state.pros.length > 0) {
        state.pros.push(action.payload.newCustomer)
      }
      if (action.payload.isPro === 'false' && state.privates.length > 0) {
        action.payload.newCustomer.fullname =
          action.payload.newCustomer.firstname +
          ' ' +
          action.payload.newCustomer.lastname
        state.privates.push(action.payload.newCustomer)
      }
      return {
        ...state,
        customers: state.customers,
        pros: state.pros,
        privates: state.privates,
      }
    case SAVE_CUSTOMER:
      const isListed = state.customers.findIndex(
        (customer) => customer.id === action.payload.customer.id
      )
      if (isListed !== -1) state.customers[isListed] = action.payload.customer
      const cardToUpdateIndex = state.cards.findIndex(
        (customer) => customer.idCustomer === String(action.payload.customer.id)
      )
      state.cards[cardToUpdateIndex].customer = action.payload.customer

      let objectToReturn = {
        ...state,
        customers: state.customers,
        cards: state.cards,
      }

      if (action.payload.isPro === 'true' && state.pros.length > 0) {
        const indexToUpdatePro = state.pros.findIndex(
          (customer) =>
            customer.idCustomer === String(action.payload.customer.id)
        )
        objectToReturn = {
          ...state,
          customers: state.customers,
          pros: state.pros.map((customer, index) =>
            index === indexToUpdatePro
              ? { ...action.payload.customer, phone: customer?.phone || '' }
              : customer
          ),
          cards: state.cards,
        }
      }

      if (action.payload.isPro === 'false' && state.privates.length > 0) {
        const indexToUpdatePrivate = state.privates.findIndex(
          (customer) =>
            customer.idCustomer === String(action.payload.customer.id)
        )
        objectToReturn = {
          ...state,
          customers: state.customers,
          privates: state.privates.map((customer, index) =>
            index === indexToUpdatePrivate
              ? {
                  ...action.payload.customer,
                  fullname: customer.firstname + ' ' + customer.lastname,
                  phone: customer?.phone || '',
                }
              : customer
          ),
          cards: state.cards,
        }
      }

      return objectToReturn
    case SAVE_ADDRESS:
      const isAddressListed = state.addresses.findIndex(
        (address) => String(address.id) === String(action.payload.address.id)
      )
      if (isAddressListed !== -1)
        state.addresses[isAddressListed] = action.payload.address

      const cardAddressToUpdateIndex = state.cards.findIndex(
        (customer) => customer.idCustomer === action.payload.address.id_customer
      )
      const addressToUpdateIndex = state.cards[
        cardAddressToUpdateIndex
      ].addresses.findIndex(
        (address) => String(address.id) === String(action.payload.address.id)
      )

      state.cards[cardAddressToUpdateIndex].addresses[addressToUpdateIndex] =
        action.payload.address

      let objectToReturnAddress = {
        ...state,
        addresses: state.addresses,
        cards: state.cards,
      }

      if (action.payload.isPro === 'true' && state.pros.length > 0) {
        const indexToUpdatePro = state.pros.findIndex(
          (customer) =>
            String(customer.id) === action.payload.address.id_customer
        )
        objectToReturnAddress = {
          ...state,
          addresses: state.addresses,
          pros: state.pros.map((customer, index) =>
            index === indexToUpdatePro
              ? { ...customer, phone: action.payload.address.phone }
              : customer
          ),
          cards: state.cards,
        }
      }

      if (action.payload.isPro === 'false' && state.privates.length > 0) {
        const indexToUpdatePrivate = state.privates.findIndex(
          (customer) =>
            String(customer.id) === action.payload.address.id_customer
        )
        objectToReturnAddress = {
          ...state,
          addresses: state.addresses,
          privates: state.privates.map((customer, index) =>
            index === indexToUpdatePrivate
              ? { ...customer, phone: action.payload.address.phone }
              : customer
          ),
          cards: state.cards,
        }
      }

      return objectToReturnAddress

    case GET_CUSTOMERS_LIST:
      return { ...state, customers: action.payload.list }
    case GET_PROS_LIST:
      return { ...state, pros: action.payload.list }
    case GET_PRIVATES_LIST:
      return { ...state, privates: action.payload.list }
    case GET_CUSTOMER_ADDRESSES:
      for (let index = 0; index < action.payload.addresses.length; index++) {
        const element = action.payload.addresses[index]
        const isAddressFound = state.addresses.find(
          (address) => address.id === element.id
        )
        if (!isAddressFound) state.addresses.push(element)
      }
      return { ...state, addresses: state.addresses }
    case GET_CUSTOMER:
      const isCustomerFound = state.cards.find(
        (customer) => customer.idCustomer === action.payload.idCustomer
      )
      if (!isCustomerFound) state.cards.push(action.payload)
      return { ...state, cards: state.cards }
    case CREATE_ADDRESS:
      if (action.payload.isPro === '') return { ...state }
      else {
        let listedCustomer = false
        let objectToReturn = { ...state }
        const oldCardIndex = state.cards.findIndex(
          (card) => card.idCustomer === action.payload.newAddress.id_customer
        )

        if (oldCardIndex !== -1) {
          objectToReturn.cards[oldCardIndex].addresses.push(
            action.payload.newAddress
          )
        }

        if (action.payload.isPro === 'true' && state.pros.length > 0) {
          listedCustomer = state.pros.findIndex(
            (customer) =>
              String(customer.id) === action.payload.newAddress.id_customer
          )
          if (listedCustomer !== -1) {
            if (state.pros[listedCustomer].phone === '') {
              state.pros[listedCustomer].phone = action.payload.newAddress.phone
            }
            objectToReturn = {
              ...state,
              pros: state.pros.map((customer, index) =>
                index === listedCustomer
                  ? { ...customer, phone: action.payload.newAddress.phone }
                  : customer
              ),
              cards: state.cards,
            }
          }
        }

        if (action.payload.isPro === 'false' && state.privates.length > 0) {
          listedCustomer = state.privates.findIndex(
            (customer) =>
              String(customer.id) === action.payload.newAddress.id_customer
          )
          if (listedCustomer !== -1) {
            if (state.privates[listedCustomer].phone === '') {
              state.privates[listedCustomer].phone =
                action.payload.newAddress.phone
            }
            objectToReturn = {
              ...state,
              privates: state.privates.map((customer, index) =>
                index === listedCustomer
                  ? { ...customer, phone: action.payload.newAddress.phone }
                  : customer
              ),
              cards: state.cards,
            }
          }
        }

        return objectToReturn
      }
    case CHANGE_CUSTOMER_TYPE:
      const indexToUpdate = state.cards.findIndex(
        (customer) => customer.idCustomer === action.payload.idCustomer
      )

      return {
        ...state,
        privates: [],
        pros: [],
        cards: state.cards.map((card, index) =>
          index === indexToUpdate
            ? {
                ...card,
                isPro: action.payload.target === 'pro' ? 'true' : 'false',
              }
            : card
        ),
      }
    case NEW_SELF_INVOICE:
      console.log(action.payload.proAccountHistory)
      const cardToUpdateIndx = state.cards.findIndex(
        (card) =>
          card.idCustomer === action.payload.proAccountHistory.id_customer
      )
      return {
        ...state,
        cards: state.cards.map((customer, index) =>
          index === cardToUpdateIndx
            ? {
                ...customer,
                proAccountHistories: [
                  action.payload.proAccountHistory,
                  ...customer.proAccountHistories,
                ],
              }
            : customer
        ),
      }
    case GET_SOURCE_SALES:
      //payload
      //year: year,
      //idCustomer: idCustomer,
      //sales: sales,
      const iToUpdate = state.cards.findIndex(
        (customer) => customer.idCustomer === action.payload.idCustomer
      )

      const newSalesArray = state.cards[iToUpdate]?.sales || []
      if (
        newSalesArray.find(
          (yearSales) => yearSales.year === action.payload.year
        ) === undefined
      )
        newSalesArray.push(action.payload)

      return {
        ...state,
        cards: state.cards.map((customer, index) =>
          index === iToUpdate
            ? {
                ...customer,
                sales: newSalesArray,
              }
            : customer
        ),
      }

    default:
      return state
  }
}

export default customersReducer
